@import url('https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: jalnan;
  src: url('res/fonts/Jalnan.woff') format('woff'), url('res/fonts/Jalnan.ttf') format('truetype');
}

@font-face {
  font-family: 'GodoM';
  src: url('res/fonts/GodoM.eot');
  src: url('res/fonts/GodoM.eot?#iefix') format('embedded-opentype'),
    url('res/fonts/GodoM.svg#GodoM') format('svg'), url('res/fonts/GodoM.ttf') format('truetype'),
    url('res/fonts/GodoM.woff') format('woff'), url('res/fonts/GodoM.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GodoB';
  src: url('res/fonts/GodoB.eot');
  src: url('res/fonts/GodoB.eot?#iefix') format('embedded-opentype'),
    url('res/fonts/GodoB.svg#GodoB') format('svg'), url('res/fonts/GodoB.ttf') format('truetype'),
    url('res/fonts/GodoB.woff') format('woff'), url('res/fonts/GodoB.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  min-width: 100%;
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
}
a {
  text-decoration: none;
}
svg {
  overflow: hidden;
}
*.with-hover-overlay {
  position: relative;
}
*.with-hover-overlay::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  transition: all 0.1s;
  background-color: rgba(0, 0, 0, 0);
}
button:focus,
a:focus {
  outline: none;
}
*.with-hover-overlay:focus::after,
*.with-hover-overlay:hover::after,
*.with-hover-overlay.active:not(:hover)::after,
*.with-hover-overlay.active:not(:focus)::after {
  background-color: rgba(0, 0, 0, 0.2);
}
*.with-hover-overlay:hover:disabled::after {
  background-color: rgba(0, 0, 0, 0);
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
